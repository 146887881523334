
import React from 'react';

const Footer = ({ legalInfo, contactInfo }) => {
  return (
    <div className="w3-container w3-black w3-center w3-opacity w3-padding-32">
      <footer id="footer" className="footer-container w3-container w3-padding-64 w3-center w3-opacity">
        <p>Prevádzkovateľ: Jawolta s.r.o</p>
        <p>IČO: 47 472 065</p>
        <p>podpora@dobierkomat.com</p>
        <p>WhatsApp: <a href="https://wa.me/421944749525">+421944749525</a></p>
        <p><a href="/obchodnepodmienky.pdf" target="_blank" rel="noopener noreferrer">Obchodné podmienky</a></p>
      </footer>
    </div>
  );
};

export default Footer;
