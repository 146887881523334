// src/components/Documentation.js
import React, { useState } from 'react';
//import '../styles/Documentation.css'; // Import your CSS file

const Documentation = () => {
  const [selectedOption, setSelectedOption] = useState('buyer');

  const buyerBenefits = [
    "S Dobierkomatom je tvoja platba v bezpečí",
    "Ak nedostaneš to čo si si objednal môžeš tovar vrátiť naspäť predávajúcemu",
    "Ochránime ťa pred podvodnými predajcami."
  ];

  const sellerBenefits = [
    "Bezpečná platba - peniaze dostaneš,  keď kupujúci potvrdí prijatie tovaru.",
    "Vyhneš sa neprevzatým dobierkam a vyhodeným platbám za poštovné.",
    "Môžeš posielať tovar s istotou, že dostaneš zaplatené."
  ];

  const howItWorks = [
    "Nájdi si na e-bazároch tovar, ktorý si chceš zakúpiť",
    "Oslov predávajúceho a ponúkni mu platbu cez Dobierkomat",
    "Dohodni sa na cene vrátane poštovného a vypýtaj si jeho mail.",
    "Vlož peniaze predávajúcemu bezpečne do Dobierkomatu.",
    "Uisti sa či ti došlo to, čo si si objednal a potvrď transakciu."
  ];

  return (
    <div id="how-it-works" className="w3-row-padding w3-light-grey w3-padding-64 w3-container">
      <div className="w3-content" style={styles.container}>
        {/* Gear icon section with CSS class to hide on small screens */}
        <div className="w3-third w3-center w3-hide-small" style={styles.iconSection}>
          <i className="fa fa-lock fa-solid w3-padding-64 custom-text-color w3-margin-right"></i>
        </div>
        
        {/* Content section */}
        <div style={styles.contentSection}>
          <h1>Ako to funguje</h1>
          <ul className="w3-text-grey">
            {howItWorks.map((benefit, index) => <li key={index}>{benefit}</li>)}
          </ul>
          <div style={styles.buttonContainer}>
            <button
              className={`w3-button ${selectedOption === 'buyer' ? 'w3-dark-grey' : 'w3-light-grey'}`}
              onClick={() => setSelectedOption('buyer')}
              style={styles.button}
            >
              Výhody pre kupujúceho
            </button>
            <button
              className={`w3-button ${selectedOption === 'seller' ? 'w3-dark-grey' : 'w3-light-grey'}`}
              onClick={() => setSelectedOption('seller')}
              style={styles.button}
            >
              Výhody pre predávajúceho
            </button>
          </div>

          {/* Conditionally render the list based on the selected option */}
          <ul className="w3-text-grey">
            {selectedOption === 'buyer' ? (
              buyerBenefits.map((benefit, index) => <li key={index}>{benefit}</li>)
            ) : (
              sellerBenefits.map((benefit, index) => <li key={index}>{benefit}</li>)
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

// Styles using JavaScript objects
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  iconSection: {
    flex: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '250px',
  },
  contentSection: {
    flex: '2',
    paddingLeft: '20px',
    minWidth: '300px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'left',
    marginBottom: '20px',
    flexWrap: 'wrap',
  },
  button: {
    flex: '1 1 auto',
    margin: '5px',
    minWidth: '200px',
  },
};

export default Documentation;
