// src/App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";  // Import necessary modules from react-router-dom
import './App.css';
import Header from './components/Header';
import NavBar from './components/NavBar';
import Payer from './components/Payer';
import Payee from './components/Payee';
import Documentation from './components/Documentation';
import Footer from './components/Footer';

export default function App() {
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setMessage("Vklad bol vložený. Príjemcu sme notifikovali emailom. Ďakujeme za použitie Dobierkomatu.")
    }
    if (query.get("canceled")) {
      setMessage("Transakcia neprebehla. Môžte to skúsiť znova, alebo kontaktujte zákaznícku podporu.");
    } 
  }, []);

  return (
    <Router> {/* Wrap the app in Router for routing functionality */}
      <div className="container">
       {/*  <Header /> */}
        <NavBar />

        {/* Define routes using Routes and Route components */}
        <Routes>
          <Route
            path="/payer"
            element={
              <div>
                <Payer text={message} /> 
                <Footer legalInfo="Legal Info" contactInfo="Contact Info" />
              </div>
            }
          />
          <Route
            path="/payee"
            element={
              <div>
                <Payee/>
                <Footer legalInfo="Legal Info" contactInfo="Contact Info" />
              </div>}  /* Render Payee on /payee route */
          />
          <Route
            path="/"
            element={
              <div>
                <Header />
                <Documentation/>
                <Footer legalInfo="Legal Info" contactInfo="Contact Info" />
              </div>
            }
          />          
          <Route
            path="/documentation"
            element={
              <div>
                <Documentation/>
                <Footer legalInfo="Legal Info" contactInfo="Contact Info" />
              </div>
            }
          />
          <Route
            path="/footer"
            element={
              <Footer 
              legalInfo="Legal Info" contactInfo="Contact Info"              />
            }
          />
        </Routes>
      </div>
    </Router>
  );
}